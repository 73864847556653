import {Col, Space, Timeline} from "antd";
import {ACTIONS} from "../../constants";
import dayjs from "dayjs";

export const EventTimeline = ({events}) => {

  return (
    <Col span={8}>
      <Space direction='vertical'>
        <div style={{fontSize: 20, fontWeight: 'bold'}}>Event history</div>
        <Timeline
          items={events
            ?.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
            .map((item) => ({
              color: (item.type === ACTIONS.REVERT || events.some((item2) => item2.revertedId === item.updateId)) ? 'gray' : 'blue',
              children: (
                <div>
                  {`
                  ${item.type.toUpperCase()} -
                  ${item.ownerAlias} -
                  ${dayjs(item.timestamp).format('DD.MM.YY hh:mm')}
                `}
                </div>
              ),
            }))}
        />
      </Space>
    </Col>
  )
}