import {useState} from "react";
import papaparse from "papaparse";
import {Alert, Button, Space, Upload as AntUpload, App, Table} from "antd";
import {testerColumns} from "../constants";
import {requestFn, uniqueArrayByKey} from "../helpers";

const {Dragger} = AntUpload;


export const Upload = ({token}) => {
    const {notification} = App.useApp();
    const [file, setFile] = useState(null)
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const [parsed, setParsed] = useState({
        valid: [],
        invalid: []
    })

    const handleSubmit = async () => {
        setLoading(true)
        await requestFn(
            'POST',
            '/devices',
            token,
            (res) => {
                setFile(null)
                setError(null)
                setParsed({
                    valid: [],
                    invalid: [],
                })
                notification.success({message: 'Upload successful', placement: 'top'})
            },
            setError,
            parsed.valid,
        )
        setLoading(false)
    };

    const handleParse = (directFile) => {
        papaparse.parse(directFile, {
            delimiter: ';',
            skipEmptyLines: true,
            complete: (res) => {
                const data = res.data
                const transformed = data.map((row, index) => {
                    if (
                        row[0].includes('BATCH') ||
                        row[0].includes('Hardware:') ||
                        row[0].includes('PCB:') ||
                        row[0] === ''
                    ) {
                        return null
                    }
                    let finalShape = {}
                    row.map((item, index) => {
                        finalShape = {
                            ...finalShape,
                            [testerColumns[index]]: item
                        }
                    })
                    return finalShape
                }).filter(Boolean)

                const withIds = transformed.filter((item) => !!item.suid)
                const withoutIds = transformed.filter((item) => !item.suid).map((item) => ({
                    ...item,
                    reason: 'missingId'
                }))

                const {unique, unUnique} = uniqueArrayByKey(withIds, 'suid')
                setParsed({
                    valid: unique,
                    invalid: [...withoutIds, ...unUnique.map((item) => ({
                        ...item,
                        reason: 'notUnique'
                    }))]
                })
            }
        })
    }

    const tableColumns = [
        {
            title: 'Reason',
            dataIndex: 'reason',
            render: (reason) => reason === 'missingId' ? 'Missing SUID' : 'Not Unique',
        },
        {
            title: 'SUID',
            dataIndex: 'suid',
        },
        {
            title: 'LAP',
            dataIndex: 'lap',
            key: 'lap',
        },

    ]

    return (
        <Space style={{padding: 20}} align='start'>
            <Space direction='vertical'>
                <Space direction='vertical' align='start'>
                    <Dragger
                        style={{padding: 20}}
                        showUploadList={false}
                        multiple={false}
                        accept='.csv'
                        customRequest={() => true}
                        maxCount={1}
                        onChange={(e) => {
                            handleParse(e.file.originFileObj)
                            setFile(e.file.originFileObj);
                        }}
                    >
                        <Space direction='vertical' size='large'/>
                        <div style={{fontSize: 50}}>
                            📥
                        </div>
                        <div>Click or drag file to this area to upload</div>
                    </Dragger>
                    <div>{file?.name}</div>
                </Space>
            </Space>
            {!!(parsed.valid.length || parsed.invalid.length) && (
                <Space direction='vertical'>
                    <Alert
                        message={`We found ${parsed.valid.length} valid items and ${parsed.invalid.length} invalid items. Do you wish to upload valid or discard the upload?`}
                        action={
                            <Space>
                                <Button
                                    style={{marginLeft: 20}}
                                    loading={loading}
                                    onClick={handleSubmit}
                                >
                                    Upload
                                </Button>
                                <Button
                                    style={{marginLeft: 20}}
                                    disabled={loading}
                                    onClick={() => {
                                        setParsed({
                                            valid: [],
                                            invalid: []
                                        })
                                        setFile(null)
                                    }}
                                    danger
                                >
                                    Discard
                                </Button>
                            </Space>
                        }
                    />
                    {error && <Alert type='error' message={error}/>}
                    {!!parsed.invalid.length && (
                        <Table
                            columns={tableColumns}
                            dataSource={parsed.invalid}
                        />
                    )}
                </Space>
            )}
        </Space>
    );
}
