import {BE_URL} from "./constants";
import {Button} from "antd";
import {useState} from "react";

export const compareObjects = (a, b) => JSON.stringify(a) === JSON.stringify(b)
export const groupData = (data, dataKey) => data.reduce((result, item) => {
  const key = item[dataKey];
  if (!result[key]) {
    result[key] = [];
  }
  result[key].push(item);
  return result;
}, {});


export const uniqueArrayByKey = (data, key) => data.reduce((accumulator, currentObject) => {
  const existingObject = accumulator.unique.find(obj => obj[key] === currentObject[key]);
  if (!existingObject) {
    accumulator.unique.push(currentObject);
  } else {
    accumulator.unUnique.push(currentObject)
  }
  return accumulator;
}, {unique: [], unUnique: []});

export const requestFn = async (
  method,
  path,
  token,
  onSuccess,
  onError,
  data,
) => {
  const res = await fetch(BE_URL + path, {
    method: method,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token
    },
    body: JSON.stringify(data)
  }).then(async (r) => await r.json())
  if (res.ok) {
    onSuccess(res.data)
  } else {
    onError(res.message)
  }
}

export const ClipButton = ({text}) => {
  const [copied, setCopied] = useState(false)
  const copy = () => {
    navigator.clipboard.writeText(text)
    setCopied(true)
    setTimeout(() => setCopied(false), 1000)
  }
  return (
    <Button
      color={copied ? 'green' : 'blue'}
      size='small'
      onClick={copy}
    >
      copy {copied && `✅`}
    </Button>
  )
}