import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {ConfigProvider, theme, App as AntApp} from "antd";
import './rootStyle.css'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#ffa500',
          colorBgBase: '#141414'
        },
        algorithm: theme.darkAlgorithm
      }}
    >
      <AntApp style={{height: '100%'}}>
        <App/>
      </AntApp>
    </ConfigProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
