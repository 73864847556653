import {Alert, Col, Collapse, Space, Timeline, Tooltip} from "antd";
import {ACTIONS, DEVICE_TEST_STATES, TEST_STEP_STATUS} from "../../constants";
import dayjs from "dayjs";
import {InfoCircleOutlined} from "@ant-design/icons";

export const TestTimeline = ({tests, events, generateDummyTest}) => {

  const decideType = (status) => {
    switch (status) {
      case TEST_STEP_STATUS.SUCCESS:
      case DEVICE_TEST_STATES.TESTED_DEVICE_PASS:
        return 'success'
      case TEST_STEP_STATUS.FAILED:
      case TEST_STEP_STATUS.ERROR:
      case DEVICE_TEST_STATES.TESTED_DEVICE_FAIL:
      case DEVICE_TEST_STATES.TESTED_DEVICE_ERROR:
        return 'error'
      default:
        return 'info'
    }
  }
  if (!Array.isArray(tests)) {
    return null
  }
  return (
    <Col span={8}>
      <Space direction='vertical'>
        <Space>
          <div style={{fontSize: 20, fontWeight: 'bold'}}>Barel tests</div>
          {/*<Button onClick={generateDummyTest}>
            Generate dummy test for this device
          </Button>*/}
        </Space>
        {!!tests?.length && (
          <Timeline
            items={tests
              ?.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
              .map((testGroup) => ({
                color: (testGroup.status === ACTIONS.REVERT || events.some((item2) => item2.revertedId === testGroup.id)) ? 'gray' : 'blue',
                children: (
                  <Collapse
                    items={[
                      {
                        key: testGroup.id,
                        label: (
                          <div
                            style={{textDecoration: testGroup.reverted ? 'line-through' : 'none' }}
                          >
                            {`
                              ${dayjs(testGroup.timestamp).format('DD.MM.YY hh:mm')} -
                              ${testGroup.ownerAlias}
                            `}
                          </div>
                        ),
                        children: (
                          <Space direction='vertical'>
                            {Array.isArray(testGroup.testData) && testGroup.testData?.map((test) => (
                              <Alert
                                key={test.test_name}
                                type={decideType(test.test_status)}
                                message={
                                  <Collapse
                                    ghost
                                    items={[{
                                      key: test.test_name,
                                      label: (
                                        <Space>
                                          {`
                                            ${test.test_status.toUpperCase()} -
                                            ${test.test_name}
                                          `}
                                          <Tooltip
                                            title={test.test_description}
                                          >
                                            <InfoCircleOutlined />
                                          </Tooltip>
                                        </Space>
                                      ),
                                      children: (
                                        <Space direction='vertical'>
                                          {test.test_steps.map((step) => (
                                              <Alert
                                                key={step.test_step_name}
                                                type={decideType(step.test_step_status)}
                                                message={
                                                  <Space>
                                                    {`
                                                      ${step.test_step_status.toUpperCase()} -
                                                      ${step.test_step_name}
                                                    `}
                                                    <Tooltip title={step.test_step_description}>
                                                      <InfoCircleOutlined/>
                                                    </Tooltip>
                                                  </Space>
                                                }
                                              />
                                            ))}
                                        </Space>
                                      )
                                    }]}
                                  />
                                }
                                showIcon
                              />
                            ))}
                          </Space>
                        )
                      }
                    ]}
                  />
                ),
              }))}
          />
        )}
      </Space>
    </Col>
  )
}
