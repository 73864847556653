import {useEffect, useState} from "react";
import {Alert, Input, Space, Table} from "antd";
import {ClipButton, requestFn} from "../helpers";
import {useNavigate} from "react-router";
import {Link} from "react-router-dom";
import Fuse from 'fuse.js'


export const Devices = ({token}) => {
  const navigate = useNavigate()
  const [error, setError] = useState('')
  const [data, setData] = useState([])
  const [search, setSearch] = useState('')
  const [loading, setLoading] = useState(true)

  const getData = async () => {
    await requestFn('GET', '/devices', token, setData, setError)
    setLoading(false)
  }

  const columns = [

    {
      title: 'SUID',
      dataIndex: 'suid',
      key: 'suid',
      render: (suid) => (
        <div style={{display: 'flex', justifyContent: 'space-between' }}>
          <Link to={`/device/${suid}`}>{suid}</Link>
          <ClipButton text={suid} />
        </div>
      )
    },
    {
      title: 'MAC',
      dataIndex: 'mac',
      key: 'mac',
      render: (mac) => (
        <div style={{display: 'flex', justifyContent: 'space-between' }}>
          <div> {mac} </div>
          <ClipButton text={mac} />
        </div>
      )
    },

  ];

  const fuse = new Fuse(data, {
    keys: ['suid', 'mac'],
    threshold: 0.6,
  })
  const fuseData = fuse.search(search).map(({item}) => item)

  useEffect(() => {
    getData()
  }, []);

  return (
    <Space direction='vertical' style={{padding: 30, width: '100%'}}>
      {error && <Alert type='error' message={error}/>}
      Search
      <Space>
        <Input
          value={search}
          onChange={(event) => setSearch(event.target.value)}
          style={{width: 300}}
          onPressEnter={() => {
            if(fuseData.length === 1) {
              navigate('/device/' + fuseData[0]?.suid)
            }
          }}
        />
        <div>
          Current search: <b>{fuseData.length}</b> devices
        </div>
      </Space>
      <Table
        columns={columns}
        dataSource={fuseData.length ? fuseData : data}
        pagination
        loading={loading}
      />
    </Space>
  )
}