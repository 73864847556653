import {DEVICE_TEST_STATES, TEST_STEP_STATUS} from "./constants";

export const dummyTestData = [
  {
  test_name: 'Touch Test',
  test_command: 'TOUCH_TEST',
  test_description: 'Touch Test will press both sides of button 5 times',
  test_status: DEVICE_TEST_STATES.TESTED_DEVICE_FAIL,
  test_version: 1,
  test_steps: [
    {
      test_step_name: 'Enter touch mode',
      test_step_description: 'Send protocol command and enter touch mode',
      test_step_command: 'PREPARE_TOUCH',
      test_step_timetout_seconds: 25,
      test_step_retries: 3,
      test_step_status: TEST_STEP_STATUS.SUCCESS,
    },
    {
      test_step_name: 'Enter touch mode 2',
      test_step_description: 'Send protocol command and enter touch mode',
      test_step_command: 'TOUCH_PREPARE_STEP',
      test_step_timetout_seconds: 25,
      test_step_retries: 3,
      test_step_status: TEST_STEP_STATUS.FAILED,
    }
  ]
},
  {
    test_name: 'Sound Test',
    test_command: 'SOUND_TEST',
    test_description: 'Sound Test will test both speakers for 5 seconds',
    test_status: DEVICE_TEST_STATES.UNFINISHED_DEVICE,
    test_version: 1,
    test_steps: [
      {
        test_step_name: 'Left side',
        test_step_description: 'Send protocol command and enter sound mode',
        test_step_command: 'LEFT_PREPARE_SOUND',
        test_step_timetout_seconds: 25,
        test_step_retries: 3,
        test_step_status: TEST_STEP_STATUS.NOT_STARTED,
      },
      {
        test_step_name: 'Right side',
        test_step_description: 'Send protocol command and enter sound mode',
        test_step_command: 'RIGHT_PREPARE_STEP',
        test_step_timetout_seconds: 25,
        test_step_retries: 3,
        test_step_status: TEST_STEP_STATUS.NOT_STARTED,
      }
    ]
  }
]