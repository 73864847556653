import {Space, Input as AntInput} from "antd";


export const Input = ({value, onChange, disabled, label}) => (
  <Space direction='vertical' size='small'>
    <label>{label}</label>
    <AntInput
      disabled={disabled}
      value={value}
      onChange={(e) => onChange(e.target.value)}
    />
  </Space>
)