import {Button, Col, Space, Input} from "antd";
import dayjs from "dayjs";
import {useState} from "react";

const {TextArea} = Input

export const Comments = ({comments, postComment, userId}) => {
  const [newComment, setComment] = useState('')
  const [loading, setLoading] = useState(false)
  return (
    <Col span={8}>
      <Space direction='vertical' style={{width: '100%'}}>
        <div style={{fontSize: 20, fontWeight: 'bold'}}>Comments</div>
        <div>
          <div style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: 10,
          }}>
            {comments?.map((comment) => (
              <div style={{
                background: comment?.owner === userId ? 'green' : 'blue',
                alignSelf: comment?.owner === userId ? 'flex-end' : 'flex-start',
                borderRadius: 10,
                padding: 10,
                display: 'flex',
                flexDirection: 'column',
                gap: 10,
              }}>
                <div>
                  {`
                    ${comment.ownerAlias} - ${dayjs(comment.timestamp).format('DD.MM.YY hh:mm')}
                  `}
                </div>
                <div>{comment.commentData}</div>
              </div>
            ))}
          </div>
        </div>
        <div>
          <Space align='end'>
            <TextArea
              placeholder='Add comment'
              onChange={(e) => setComment(e.target.value)}
              value={newComment}
            />
            <Button
              loading={loading}
              onClick={async () => {
                setLoading(true)
                await postComment(newComment).then(() => {
                  setLoading(false);
                  setComment('')
                })
              }}
            >
              Add
            </Button>
          </Space>
        </div>
      </Space>
    </Col>
  )
}
