import {useEffect, useState} from "react";
import {Alert, App, Row, Space} from "antd";
import {useNavigate, useParams} from "react-router";

import {compareObjects, requestFn} from "../../helpers";
import {dummyTestData} from "../../dummyTestData";
import {Form} from "./Form";
import {EventTimeline} from "./EventTimeline";
import {TestTimeline} from "./TestTimeline";
import {Comments} from "./Coments";

export const Detail = ({token}) => {
  const [error, setError] = useState('')
  const {id} = useParams()
  const navigate = useNavigate()
  const [form, setForm] = useState()
  const [data, setData] = useState()
  const [saving, setSaving] = useState(false)
  const {notification} = App.useApp();

  const getData = async () => {
    await requestFn(
      'GET',
      '/devices/' + id,
      token,
      (res) => {
        if (id === res.mac) {
          navigate('/device/' + res.suid)
        }
        setData(res)
        setForm(res)
        setError('')
      },
      setError,
    )
  }

  const postData = async () => {
    setSaving(true)
    await requestFn(
      'POST',
      '/devices/' + id,
      token,
      (res) => {
        notification.success({message: 'Update succesful'})
        setData(res)
        setForm(res)
      },
      setError,
      form
    )
    setSaving(false)
  }

  const generateDummyTest = async () => {
    await requestFn(
      'POST',
      '/devices/' + id + '/tests',
      token,
      (res) => {
        notification.success({message: 'Dummy test generated'})
        getData()
      },
      setError,
      dummyTestData
    )
  }
  const postComment = async (comment) => {
    await requestFn(
      'POST',
      '/devices/' + id + '/comments',
      token,
      (res) => {
        notification.success({message: 'Comment added'})
        getData()
      },
      setError,
      comment
    )
  }
  const isSame = compareObjects(form, data)

  useEffect(() => {
    getData()
  }, []);

  return (
    <Space direction='vertical' style={{padding: 20}}>
      {error && <Alert type='error' message={error}/>}
      <Form
        form={form}
        setForm={setForm}
        saving={saving}
        postData={postData}
        id={id}
        isSame={isSame}
      />
      <Row>
        <EventTimeline events={data?.events || []}/>
        <TestTimeline
          tests={data?.tests || []}
          events={data?.events || []}
          generateDummyTest={generateDummyTest}
        />
        <Comments
          userId={token}
          comments={data?.comments || []}
          postComment={postComment}
        />
      </Row>
    </Space>
  )
}